<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    full-buttons
    submit-title="Archive"
    title="Archive Alert"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="gl-modal__row">
      Are you sure you want to archive alert <b>{{ data.title || '' }}</b>?
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
// Vuex
import { mapActions } from "vuex";

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    ...mapActions('alerts', ['changeArchiveFlag']),
    onSubmit() {
      this.changeArchiveFlag({ id: this.data.id, archive: true }).then(() => {
        this.$emit('submit')
        this.$emit('close')
      })
    },
  },
}
</script>
