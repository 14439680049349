<template>
  <div class="fullwidth">
    <div class="table-wrap table-wrap__unoverflow gl-table-unshadow">
      <o-table
        ref="alertTableActive"
        class="alerts-table main-table-wrap"
        :class="{ 'o-table-has-pagination': list.length }"
        :data="list"
        detail-transition="fade"
        detailed
        detailed-class="eth-tx-actions-detailed"
        hoverable
        :loading="loading"
        striped
      >
        <template
          slot="detail"
          slot-scope="props"
        >
          <div class="alert-actions-detailed flex">
            <div class="flex-3 details-spacing">
              <div class="o-table__th--label mb-1">
                Trigger conditions met
              </div>
              <div
                v-for="(item, index) in props.row.triggersConditionMet"
                :key="index"
              >
                <div class="mb-1">
                  <div v-if="item && item.prop === 'amount'">
                    {{ item.direction.label }} - Amount {{ item.trigger.label }} {{ item.percentOfTxs.label.toLowerCase() }} {{ Number(item.amount) }} {{ (props.row.coin ? props.row.coin.toUpperCase() : '--') }}
                  </div>
                  <div v-else-if="item.prop === 'amountCur'">
                    {{ item.direction.label }} - Amount {{ item.trigger.label }} {{ item.percentOfTxs.label.toLowerCase() }} {{ Number(item.amount) }}
                  </div>
                  <div v-else-if="item && item.prop === 'funds.score'">
                    {{ item.direction.label }} - Amount {{ item.trigger.label }} {{ item.scoreFrom }}-{{ item.scoreTo }} with % of TX {{ item.percentOfTxs.label.toLowerCase() }} {{ item.share }}
                  </div>
                  <div v-else-if="item && item.prop === 'owner'">
                    {{ item.direction.label }} - Amount {{ item.trigger.label }}
                    <span
                      v-for="({ owner }, i) in item.owner"
                      :key="i"
                    >
                      {{ owner }} <span v-if="index !== item.owner.length - 1">,</span>
                    </span>
                    with % of TX {{ item.percentOfTxs.label.toLowerCase() }} {{ item.share }}
                  </div>
                  <div v-else-if="item && item.prop === 'funds.id'">
                    {{ item.direction.label }} - Amount Received from
                    <span v-if="item.tags.length">
                      Tags:
                      <GlTag
                        v-for="(tag) in item.tags"
                        :key="tag._id"
                        class="mr-1"
                        :score="tag.score"
                        :tag="tag.name"
                      />
                      and
                    </span>
                    Types:
                    <GlTag
                      v-for="(type) in item.types"
                      :key="type._id"
                      class="mr-1"
                      :score="type.score"
                      :tag="type.name"
                    />
                    with % of TX {{ item.percentOfTxs.label.toLowerCase() }} {{ item.share }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <o-table-column
          v-slot="props"
          field="priority"
          label="Priority"
          sortable
        >
          <div class="flex align-flex-end">
            <div style="min-width: 30px">
              <gl-icon
                v-if="!props.row.read"
                name="unread"
              />
            </div>
            <div v-if="props.row.priority === undefined">
              --
            </div>
            <gl-priority-chip
              v-else
              class="capitalize"
              :label="priorityMapLabels[props.row.priority] || '--'"
              :priority-level="props.row.priority"
              rounded="s"
              uppercase-mode
            />
            <div
              v-if="props.row.priority !== undefined"
              class="priority-border"
              :style="{'background-color': getPriorityColor(props.row.priority) || 'transparent'}"
            />
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="eventType"
          label="Event type"
          sortable
        >
          {{ props.row.title || '--' }}
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="directTx"
          label="Direct tx"
          sortable
        >
          <div
            class="link"
            @click="routeTo(props.row.directTx, 'tx', props.row.coin)"
          >
            {{ props.row.directTx ? trancateString(props.row.directTx, 7) : '--' }}
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="wallet"
          label="Wallet"
          sortable
        >
          <div
            v-if="props.row.addresses.length > 1"
            class="pointer"
            @click="setAddresses(props.row.addresses)"
          >
            {{ props.row.addresses.length }} wallets
          </div>
          <div v-else>
            <div
              v-for="(wallet, index) in props.row.addresses"
              :key="index"
              class="link"
            >
              <div @click="routeTo(wallet, 'address', props.row.coin)">
                {{ wallet ? trancateString(wallet, 7) : '--' }}
              </div>
            </div>
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="updatedAt"
          label="Date & Time"
          sortable
        >
          {{ props.row.createdAt ? formatDate(new Date(props.row.createdAt), 'dd.MM.yyyy HH:mm') : '--' }}
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="coin"
          label="Blockchain"
          sortable
        >
          {{ props.row.coin ? props.row.coin.toUpperCase() : '--' }}
        </o-table-column>
        <o-table-column
          v-slot="props"
          label="Actions"
          width="180px"
        >
          <div class="flex">
            <gl-icon
              v-if="props.row.alertId"
              class="pointer mr-2"
              :disabled="!props.row.alertId || !props.row.title"
              height="20"
              name="alert-edit"
              width="20"
              @click="editAlert(props.row)"
            />
            <gl-icon
              class="pointer"
              height="20"
              :name="!props.row.expand ? 'close-details' : 'open-details'"
              width="20"
              @click="toggleDetails(props)"
            />
          </div>
        </o-table-column>
        <template #empty>
          <div
            v-if="loading"
            class="flex align-center justify-center"
          >
            <gl-loader />
          </div>
          <div
            v-else
            class="empty-users-data flex column align-center"
          >
            <gl-icon
              :height="24"
              name="statistic"
              :width="24"
            />
            No data here yet
          </div>
        </template>
      </o-table>
      <div class="flex space-between pa-1">
        <div class="flex align-center pa-3">
          <div class="mr-2 fs-14 bold">
            Rows per page:
          </div>
          <vSelect
            v-model="perPage"
            class="stat-select__pagination mr-1"
            :clearable="false"
            :options="pagesOptions"
            @input="countChange"
          />
        </div>
        <o-pagination
          v-if="list.length && totalPages > 1"
          class="stat-pagination"
          :current.sync="currentPage"
          order="right"
          :per-page="perPage"
          simple
          :total="total"
          @change="pageChange"
        >
          <o-pagination-button
            slot="previous"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page mr-2"
              :disabled="props.page.disabled"
              icon="left"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>

          <o-pagination-button
            slot="next"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page"
              :disabled="props.page.disabled"
              icon="right"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>
        </o-pagination>
      </div>
    </div>
    <AddressesListModal
      v-model="openAddressesListModal"
      :addresses="selectedAddress"
      @close="openAddressesListModal = false"
    />
  </div>
</template>
<script>
// Components
import vSelect from "vue-select";
import GlIcon from "@/components/gl-icon.vue";
import GlLoader from "@/components/gl-loader.vue";
import GlMenuItem from "@/components/gl-menu-item.vue";
import GlPriorityChip from "@/components/gl-priority-chip.vue";
import AddressesListModal from "@/pages/alerts/modals/AddressesListModal.vue";
// Utils
import { formatDate } from "@/utils/format-date";
import { trancateString } from "@/utils/text-formatter";
import { getPriorityColor, priorityMapLabels } from "@/utils/priority";
// Vuex
import { mapActions, mapMutations, mapState } from "vuex";
import { selectionDataByTrigger } from "@/utils/alerts";
import GlTag from "@/components/gl-tag.vue";

export default {
  components: {
    GlTag,
    GlIcon,
    vSelect,
    GlLoader,
    GlMenuItem,
    GlPriorityChip,
    AddressesListModal
  },
  data() {
    return {
      loading: false,
      list: [],
      types: [],
      tags: [],
      selectedAddress: [],
      archivedNotification: null,
      openAddressesListModal: false,
      openConfirmArchiveModal: false,
      alertsCalculateHandler: null,
      pagesOptions: [5, 10, 15, 20, 50],
      currentPage: 1,
      perPage: 50,
      totalPages: 1,
      total: 1,
    }
  },
  computed: {
    ...mapState('analytics', ['coinData', 'currencyList']),
    priorityMapLabels() {
      return priorityMapLabels
    },
  },
  created() {
    this.alertsGettingInterval(100)
  },
  beforeDestroy() {
    clearTimeout(this.alertsCalculateHandler);
  },
  beforeRouteLeave(from, to, next) {
    clearTimeout(this.alertsCalculateHandler);
    next()
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    trancateString,
    getPriorityColor,
    ...mapActions('alerts', ['getNotificationList', 'changeArchiveFlag', 'getAlertsById']),
    ...mapActions('types', ['getTypes']),
    ...mapActions('tagging', ['getTags']),
    formatDate,
    ...mapMutations({
      SET_NOTIFICATION_COUNT: 'alerts/SET_NOTIFICATION_COUNT',
      SET_COIN_DATA: 'analytics/SET_COIN_DATA',
      SET_COIN_TYPE: 'analytics/SET_COIN_TYPE'
    }),
    routeTo(val, type, coinKey) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { [type]: val, type: coinKey }})

      window.open(href, '_blank')
    },
    alertsGettingInterval(interval = 8000) {
      this.alertsCalculateHandler = setTimeout(() => {
        this.loadData(false).finally(() => this.alertsGettingInterval())
      }, interval);
    },
    async toggleDetails(props) {
      if (!props.row.read) {
        await this.changeArchiveFlag({ id: props.row.id, read: true, category: 'inbox' });
        const index = this.list.findIndex(el => el.id === props.row.id);

        if (index !== -1) {
          this.list[index].read = true;
        }
      }

      const id = props.row.alertId;
      const coinDataAlert = this.currencyList.find(el => el.key === props.row.coin) || this.coinData;

      await this.SET_COIN_DATA(coinDataAlert);
      await this.SET_COIN_TYPE(coinDataAlert.key);

      const { data: typesData, success: typesSuccess } = await this.getTypes({});
      this.types = typesSuccess ? (typesData?.typeData || []) : [];

      const { data: tagsData, success: tagsSuccess } = await this.getTags({});
      this.tags = tagsSuccess ? (tagsData?.tagData || []) : [];

      const alertData = await this.getAlertsById(id);
      const triggersConditionMet = Array.isArray(props?.row?.results)
        ? props?.row?.results.map((res, index) => {
          if (res) {
            return selectionDataByTrigger(alertData.groupMatches[index], alertData, { types: this.types, tags: this.tags, coinDataAlert });
          }
        })
        : [];

      const toggleItemIndex = this.list.findIndex(el => el.id === props.row.id)

      if (toggleItemIndex !== -1 && triggersConditionMet.length) {
        this.$refs.alertTableActive.toggleDetails(props.row);
        this.list[toggleItemIndex].expand = Boolean(!this.list[props.index].expand);
        this.list[toggleItemIndex].triggersConditionMet = triggersConditionMet;
      }
    },
    countChange() {
      this.loadData()
    },
    editAlert({ alertId }) {
      this.$router.push({
        name: 'alerting',
        params: {
          alertId
        }
      });
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData(true)
    },
    formattedSendData() {
      if (this.total <= (this.currentPage - 1) * this.perPage && this.currentPage !== 1) {
        this.currentPage = this.currentPage - 1
      }

      return {
        $limit: this.perPage,
        $skip: (this.currentPage - 1) * this.perPage,
        category: 'archive',
        archive: true
      }
    },
    async loadData(isNeedLoading = true) {
      if (this.$refs.alertTableActive?.openedDetailed?.length && !isNeedLoading) {
        return
      }

      if (isNeedLoading) {
        this.loading = true
      }

      const sendParams = this.formattedSendData()

      await this.getNotificationList(sendParams).then((data) => {
        this.list = data?.items || []

        this.total = data.count || 0
        this.totalPages = Math.ceil(this.total / this.perPage)
      }).finally(() => {
        this.loading = false
      })
    },
    setAddresses(addresses) {
      console.log(addresses)
      this.selectedAddress = addresses || []
      if (this.selectedAddress.length) {
        this.openAddressesListModal = true
      }
    },
  },
}
</script>

<style>
.alerts-table .o-table__td-chevron {
  display: none;
}

.alerts-table .o-table__td {
  position: relative;
}

.alerts-table .o-table__th--detailed {
  display: none;
}
</style>
