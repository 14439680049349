<template>
  <GlPageWrap
    hide-title-on-mobile
    title="Alerts"
  >
    <template #actions>
      <GlButton
        class="m-fullwidth"
        dark
        large
        :loading="loading"
        title="Mark all as read"
        @click="markAllAsRead"
      />
    </template>
    <div class="alert-wrap">
      <o-tabs
        v-model="activeTab"
      >
        <o-tab-item
          label="Active Alerts"
          :value="'inbox'"
        >
          <ActiveAlertsList />
        </o-tab-item>

        <o-tab-item
          label="Archive"
          :value="'archive'"
        >
          <ArchiveAlertsList />
        </o-tab-item>
      </o-tabs>
    </div>
  </GlPageWrap>
</template>
<script>
// Components
import GlPageWrap from "@/components/layout/gl-page-wrap.vue";
import ActiveAlertsList from "@/pages/alerts/components/ActiveAlertsList.vue";
import ArchiveAlertsList from "@/pages/alerts/components/ArchiveAlertsList.vue";
import GlButton from "@/components/gl-button.vue";
// Vuex
import { mapActions, mapMutations } from "vuex";

export default {
  components: {
    GlButton,
    ArchiveAlertsList,
    ActiveAlertsList,
    GlPageWrap
  },
  data() {
    return {
      activeTab: 'inbox',
      loading: false
    }
  },
  methods: {
    ...mapActions('alerts', ['readAll']),
    ...mapMutations('alerts', ['SET_NOTIFICATION_COUNT']),
    markAllAsRead() {
      this.loading = true
      this.readAll({ category: this.activeTab }).then(() => {
        this.SET_NOTIFICATION_COUNT(0)
      }).finally(() => {
        this.loading = false
      })
    }
  },
}
</script>

<style>
.alert-wrap {
  background-color: var(--white);
  border-radius: 3px;
}
</style>

